























































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { UpdateUserDto, UserType } from '@bertazzoni/common/src/models/user.model'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'

@Component({
  components: { Loader, CheckBox }
})
export default class UpdateUser extends Vue {
  private pageLoaded = false
  private languageList: string[] = []
  private entityList: string[] = []
  private user: UpdateUserDto = new UpdateUserDto()

  async created(): Promise<void> {
    const userInfo = await UsersService.findOne(this.$route.params.id)
    this.user.userType = userInfo.type
    this.user.buildDtoFromUser(userInfo)
    this.languageList = await getLanguageList(true)
    this.entityList = [this.user.entityAccess]
    this.pageLoaded = true
  }

  goToUserList(): void {
    this.$router.push(`/users`)
  }

  async updateUser(): Promise<void> {
    await UsersService.updateOne(this.$route.params.id, this.user)
    this.$router.push(`/users`)
  }
}
